import React from 'react';
import './404.styles.css';
import { Link } from 'react-router-dom';

const ErrorPage = () => (
    <div id="notfound">
        <div class="notfound">
            <div class="notfound-404">
                <h1>404</h1>
                <h2>Page not found</h2>
            </div>
            <Link to="/">Homepage</Link>
            <p>The page you are looking for does not exist, it might have been removed, had its name changed or is temporarily unavailable</p>
            {/**<p>Stránka kterou hledáte neexistuje. Mohla být odstraněna, její jméno mohlo být změněno nebo je jen dočasně nedostupná</p> */}
        </div>
    </div>
)
export default ErrorPage;