import React from 'react';
import client from '../service/client';
import './projects-detail.styles.css';

import AndroidLogo from '../../img/android_logo.svg';
import AppleLogo from '../../img/apple_logo.svg';
import LinuxLogo from '../../img/linux_logo.svg';
import WindowsLogo from '../../img/windows_logo.svg';
import { Slide } from 'react-slideshow-image';


const properties = {
    duration: 5000, //původně 5000
    transitionDuration: 500,
    infinite: true,
    indicators: false,
    arrows: true,
    pauseOnHover: false,
    onChange: (oldIndex, newIndex) => {
        console.log(`slide transition from ${oldIndex} to ${newIndex}`);
    }
}
class ProjectsDetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            matchId: props.match.params.id,
            article: null
        }
    }
    componentDidMount() {

        if (this.props.match.params.id) {
            client.getEntries({ content_type: 'projectData', 'fields.id': this.props.match.params.id }).then((response) => {
                this.setState({ article: response.items[0] })
            })
        }

    }
    render(props) {
        if (!this.state.article) {
            return (
                <h1>Not found :(</h1>
            )
        }
        else {

            return (
                <div>

                    <img src={`${this.state.article.fields.backgroundImage.fields.file.url}?w=1920&h=1080`} alt="" className="detailMainImage" />
                    <div className="detailProject" style={{ background: `${this.state.article.fields.backgroundColor}` }}>
                        <div className="textPart">
                            <h2>{this.state.article.fields.headline}</h2>
                            {this.state.article.fields.downloadButtonEnabled ? <a href={this.state.article.fields.downloadButtonLink} target="blank">GET HERE</a> : null}

                            <div className="icons">
                                {this.state.article.fields.androidSupport ? <img src={AndroidLogo} alt="Android" className="OS" /> : null}
                                {this.state.article.fields.appleSupport ? <img src={AppleLogo} alt="Apple" className="OS" /> : null}
                                {this.state.article.fields.linuxSupport ? <img src={LinuxLogo} alt="Linux" className="OS" /> : null}
                                {this.state.article.fields.windowsSupport ? <img src={WindowsLogo} alt="Windows" className="OS" /> : null}
                            </div>
                            <p>{this.state.article.fields.content}</p>
                        </div>

                        {this.state.article.fields.slideshowEnabled ?
                            <Slide {...properties}>
                                {this.state.article.fields.detailGalleryImages.map((images) => {

                                    return (
                                        <div>
                                            <div className="each-slide-detail">
                                                <div style={{ 'backgroundImage': `url(${images.fields.file.url})` }}></div>

                                            </div>
                                        </div>



                                    )
                                })}
                            </Slide>
                            : null}
                        <div style={{ 'backgroundImage': `url(${this.state.article.fields.backgroundImage.fields.file.url})` }}></div>



                    </div>


                </div>



            )
        }
    }





}

export default ProjectsDetail;

{/*<img src={images.fields.file.url} alt="" srcset="" />*/ }