import React from 'react';
import client from '../service/client';
import './news-detail.styles.css';

import marked from 'marked';
import DOMPurify from 'dompurify';

class NewsDetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            matchId: props.match.params.id,
            article: null
        }
    }
    componentDidMount() {

        if (this.props.match.params.id) {
            client.getEntries({ content_type: 'newsData', 'fields.id': this.props.match.params.id }).then((response) => {
                this.setState({ article: response.items[0] })
            })
        }
    }
    render() {

        if (!this.state.article) {
            return (
                <div>
                </div>
            )
        }
        else {
            return (
                <div>
                    {this.state.article.fields.backgroundImageEnabled ?
                        <img src={this.state.article.fields.backgroundImage.fields.file.url} alt="" className="detailMainImage" />
                        :
                        <div className="aboutDetailDivider"></div>
                    }
                    <div className="detailNews" style={{ background: `${this.state.article.fields.backgroundColor}` }}>
                        <div className="textPartNews">
                            <h2>{this.state.article.fields.headline}</h2>
                            <h5>{this.state.article.fields.date}</h5>
							<div dangerouslySetInnerHTML={{__html: marked(DOMPurify.sanitize(this.state.article.fields.content))}}></div>
							{/*<p>{this.state.article.fields.content}</p>*/}
                            {/*  <SlideshowDetail matchId={matchId} />*/}

                        </div>

                    </div>

                </div>

            )
        }

    }

}

export default NewsDetail