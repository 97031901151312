import React from 'react';

import './footer.styles.css';
import SocialMediaIcons from '../social-media-icons/social-media-icons.component';

const Footer = () => {
    return (
        <div className="footer">
            <div className="mainFooterContent">
                <div className="aboutUsFooter">
                    <h2>ABOUT US</h2>
                    <p>We are a fairly new company based in Czech Republic and we are focused on making software, specifically video games. As a team we are frequently participating in publicly hosted gamejams and have made several game entries for these events. We are trying to involve our community in the development process of our bigger titles via streams and suggestions.</p>
                </div>
                <div className="contactUsFooter">
                    <h2>CONTACT US</h2>
                    <div className="contacts">
						<div className="eachContact">
                            <p>Business proposals, questions etc.</p>
                            <p>contact@detailsoftware.cz</p>
                        </div>
                        {/*<div className="eachContact">
                            <p>Aleš Herink</p>
                            <p>Founder / 'Mastermind'</p>
                            <p>john.doe@gmail.com</p>
                            <p>+42077722333</p>
                        </div>
                        <div className="eachContact">
                            <p>Tomáš Arazim</p>
                            <p>Founder</p>
                            <p>john.doe@gmail.com</p>
                            <p>+42077722333</p>
                        </div>*/}
                    </div>
                </div>
                {/*<div className="address">
                    <h2>ADDRESS</h2>
                    <address>
                        Street 12/1<br />
                        Prague<br />
                        Czech Republic<br />
                    </address>
                </div>*/}
            </div>
            <SocialMediaIcons />

            <p className="copyright">Copyright © 2020 | By Martin Zvozil</p>


        </div>
    )
}
export default Footer