import React from 'react';
import { Link } from 'react-router-dom';
import './projects.styles.css';
import client from '../service/client';


import sampleImage from '../../img/sample_render.jpg';
import AndroidLogo from '../../img/android_logo.svg';
import AppleLogo from '../../img/apple_logo.svg';
import LinuxLogo from '../../img/linux_logo.svg';
import WindowsLogo from '../../img/windows_logo.svg';

class Projects extends React.Component {
    constructor() {
        super()
        this.state = { articles: [] }
    }
    componentDidMount() {

        client.getEntries({ content_type: 'projectData' }).then((response) => {
            this.setState({ articles: response.items })
        })
    }
    render() {
        return (
            <div className="projects">
                <h1>Projects</h1>

                <div className="allProjects">

                    {this.state.articles.map((article, i) => {
                        return (
                            <Link to={`/projects/${article.fields.id}`} className="detailLink">
                                <div className="eachProject" style={{ background: `${article.fields.backgroundColor}` }}>
                                    <div className="imagePart" style={{ backgroundImage: `url(${article.fields.backgroundImage.fields.file.url}?w=1920&h=1080&fit=fill)` }}>
                                        <div className="icons">
                                            {article.fields.androidSupport ? <img src={AndroidLogo} alt="Android" className="OS" /> : null}
                                            {article.fields.appleSupport ? <img src={AppleLogo} alt="Apple" className="OS" /> : null}
                                            {article.fields.linuxSupport ? <img src={LinuxLogo} alt="Linux" className="OS" /> : null}
                                            {article.fields.windowsSupport ? <img src={WindowsLogo} alt="Windows" className="OS" /> : null}
                                        </div>
                                    </div>
                                    <div className="textPart">
                                        <h2>{article.fields.headline}</h2>
                                        <p>{`${article.fields.content.substr(0, 800)}...`}</p>
                                    </div>

                                </div>
                            </Link>


                        )
                    })}

                    {/**      {ProjectData.map((PostDetail, index) => {
                        return (
                            <Link to={`/projects/${PostDetail.id}`} className="detailLink">
                                <div className="eachProject" style={{ background: `${PostDetail.backgroundColor}` }}>
                                    <div className="imagePart" style={{ backgroundImage: `url(${PostDetail.backgroundImage})` }}>
                                        <div className="icons">
                                            {PostDetail.androidSupport ? <img src={AndroidLogo} alt="Android" className="OS" /> : null}
                                            {PostDetail.appleSupport ? <img src={AppleLogo} alt="Apple" className="OS" /> : null}
                                            {PostDetail.linuxSupport ? <img src={LinuxLogo} alt="Linux" className="OS" /> : null}
                                            {PostDetail.windowsSupport ? <img src={WindowsLogo} alt="Windows" className="OS" /> : null}
                                        </div>
                                    </div>



                                    <div className="textPart">
                                        <h2>{PostDetail.headline}</h2>
                                        <p>{`${PostDetail.content.substr(0, 500)}...`}</p>
                                    </div>

                                </div>
                            </Link>
                        )
                    })}*/}
                </div>
            </div >
        )
    }
}

export default Projects;