import React from 'react';
import { Link } from 'react-router-dom';
import client from '../service/client';

import './news.styles.css';
import sampleImage from '../../img/office_sample.jpeg';
import NewsData from '../../dataFiles/newsData.json';

import marked from 'marked';
import DOMPurify from 'dompurify';

class News extends React.Component {
    constructor() {
        super()
        this.state = { articles: [] }
    }
    componentDidMount() {

        client.getEntries({ content_type: 'newsData' }).then((response) => {
            this.setState({ articles: response.items })
        })
    }
    render() {
        return (
            <div className="news">
                <h1>News</h1>

                <div className="allNews">
                    {this.state.articles.map((article, i) => {
                        return (
                            <Link to={`/news/${article.fields.id}`} className="detailLink">
                                <div className="eachNews" style={{ background: `${article.fields.backgroundColor}` }}>
                                    {article.fields.backgroundImageEnabled ?
                                        <div className="imagePartNews" style={{ backgroundImage: `url(${article.fields.backgroundImage.fields.file.url}?w=820&h=250&fit=fill)` }}>
                                            {/*DO NOT REMOVE!*/}  <div className="icons"></div> {/*DO NOT REMOVE!*/}
                                        </div>

                                        :
                                        null
                                    }

                                    {article.fields.backgroundImageEnabled ?
                                        <div className="textPartNews">
                                            <h2>{article.fields.headline}</h2>
                                            <h5>{article.fields.date}</h5>
                                            <div dangerouslySetInnerHTML={{__html: `${marked(DOMPurify.sanitize(article.fields.content.substr(0, 800)))}...`}}></div>
                                        </div>
                                        :
                                        <div className="textPartNewsNoImage">
                                            <h2>{article.fields.headline}</h2>
                                            <h5>{article.fields.date}</h5>
                                            <div dangerouslySetInnerHTML={{__html: `${marked(DOMPurify.sanitize(article.fields.content.substr(0, 800)))}...`}}></div>
                                        </div>
                                    }

                                </div>
                            </Link>
                        )
                    })}
                </div>


            </div>
        )
    }
}

export default News;