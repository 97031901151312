import React from 'react';
import { Link } from 'react-router-dom';
import client from '../service/client';

import Slideshow from '../slider/slider.component';

import './homepage.styles.css';
import newsSnippetImage from '../../img/document.svg';





class Homepage extends React.Component {
    constructor() {
        super()
        this.state = { articles: [] }
    }
    componentDidMount() {

        client.getEntries({ content_type: 'newsData' }).then((response) => {
            this.setState({ articles: response.items })
        })
    }
    render() {
        return (
            <div className="homepage">
                <Slideshow />
                <div className="mainContent">
                    <h2>Welcome!</h2>
                    <p>We play, make and love games. Currently we are working on our big project <b>Divided Zero</b> for PC and on our smaller mobile game <b>Heat</b>.
					</p>
                </div>

                <div className="newsSnippet">


                    {this.state.articles.map((article, i) => {
                        return (
                            article.fields.showInRecentUpdates == true ?
                                <div className="eachNewsSnippet">
                                    <img src={newsSnippetImage} alt="File icon" />
                                    <hr />
                                    <h2><u>{article.fields.headline}</u></h2>
                                    <p> {article.fields.firstUpdateSentence ? `- ` + article.fields.firstUpdateSentence : null}</p>
                                    <p> {article.fields.secondUpdateSentence ? `- ` + article.fields.secondUpdateSentence : null}</p>
                                    <p> {article.fields.thirdUpdateSentence ? `- ` + article.fields.thirdUpdateSentence : null}</p>
									<div className="moreCenter">
										<Link to={`/news/${article.fields.id}`}>show more</Link>
									</div>
                                </div>
                                : null
                        )
                    })}



                </div>
            </div>
        )
    }
}
export default Homepage;