import React from 'react';
import { Link } from 'react-router-dom';

import './header.styles.css';
import logo from '../../img/detail_software_logo.png';

const Header = () => {
    return (
        <div className="header">
            <Link to="/" className="navTag">
                <h1 className="logoText">DETAIL SOFTWARE</h1>
                <img src={logo} className="logo" alt="" />
            </Link>
			<div id="menuWrapper">
				<label htmlFor="toggle">&#9776;</label>
				<input type="checkbox" id="toggle" />
				<div className="menu">
					<Link className="headerLink" to="/projects">Projects</Link>
					<Link className="headerLink" to="/about-us">About Us</Link>
					<Link className="headerLink" to="/news">News</Link>
					<Link className="headerLink" to="/contacts">Contacts</Link>
				</div>
			</div>
        </div>
    )
}
export default Header;