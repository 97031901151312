//Libraries
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
//Components
import Header from './Components/header/header.component';
import Homepage from './Components/homepage/homepage.component';
import Projects from './Components/projects/projects.component';
import ProjectsDetail from './Components/projects-detail/projects-detail.component';
import About from './Components/about-us/about-us.component';
import News from './Components/news/news.component';
import NewsDetail from './Components/news-detail/news-detail.component';
import Contacts from './Components/contacts/contacts.component';
import Footer from './Components/footer/footer.component';
import ErrorPage from './Components/404/404.component';
import ScrollToTop from './Components/scroll-to-top/scroll-to-top.component';
//Styles
import './App.css';




class App extends React.Component {

  render() {
    return (
      <div className="App">
        <link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap" rel="stylesheet"></link>
        <Router>
          <Header />
          <ScrollToTop>
            <Switch>
              <Route path="/" exact component={Homepage} />
              <Route path="/projects" exact component={Projects} />
              <Route path="/projects/:id" exact component={ProjectsDetail} />
              <Route path="/about-us" exact component={About} />
              <Route path="/news" exact component={News} />
              <Route path="/news/:id" exact component={NewsDetail} />
              <Route path="/contacts" exact component={Contacts} />
              <Route component={ErrorPage} />
              <Route path="/error" component={ErrorPage} />
            </Switch>
          </ScrollToTop>
          <Footer />
        </Router>

      </div>
    );
  }
}

export default App;
