import React from 'react';

import './about-us.styles.css';
import '../social-media-icons-about-us/social-media-icons-about-us.styles.css';

import maleImage from '../../img/happy_rich_guy.jpg'

import FacebookLogo from '../../img/facebook_logo.svg';
import InstagramLogo from '../../img/instagram_logo.svg';
import TwitterLogo from '../../img/twitter_logo.svg';
import TwitchLogo from '../../img/twitch_logo.svg';
import YoutubeLogo from '../../img/youtube_logo.svg';

const AboutUs = () => (
    <div className="aboutUs">
        <div className="intro">
            <h1>About Us</h1>
            <p>We are a fairly new company based in Czech Republic and we are focused on making software, specifically video games. As a team we are frequently participating in publicly hosted gamejams and have made several game entries for these events. And recently we have decided to start hosting our own gamejams. We are trying to involve our community in the development process of our bigger titles via streams and suggestions.</p>
        </div>
        <div className="eachManager">
            <img src={maleImage} alt="male" />
            <h2>ALEŠ HERINK</h2>
            <h3>Founder / 'Mastermind'</h3>
            <div className="socialMediaIconsAboutUs">
		        <a href="https://www.facebook.com/ales.herink/" target="blank"><img src={FacebookLogo} alt="Facebook" /></a>
		        <a href="https://www.instagram.com/_thecoffeeharry/" target="blank"><img src={InstagramLogo} alt="Instagram" /></a>
				{/*<a href="https://www.twitter.com/" target="blank"><img src={TwitterLogo} alt="Twitter" /></a>*/}
		        <a href="https://www.twitch.tv/thecoffeeharry/" target="blank"><img src={TwitchLogo} alt="Twitch" /></a>
		        {/*<a href="https://www.youtube.com/"><img src={YoutubeLogo} alt="Youtube" /></a>*/}	
            </div>
            <p>Everyone calls me Harry, I graduated from the school of electrical engineering in Prague, where I gradually switched over to doing 3D graphics, on which I focus on the most. I have been always fascinated by the opportunity to create something new. And that is one of the reasons why I have been creating video games since my childhood, which got us here. To the point of founding of my own company Detail Software, which I am starting together with my friend Tomáš Arazim.</p>
        </div>
        <div className="eachManager">
            <img src={maleImage} alt="male" />
            <h2>TOMÁŠ ARAZIM</h2>
            <h3>Founder</h3>
            <div className="socialMediaIconsAboutUs">
		        <a href="https://twitter.com/tomasara413/" target="blank"><img src={TwitterLogo} alt="Twitter" /></a>
		        <a href="https://www.twitch.tv/tomasara413" target="blank"><img src={TwitchLogo} alt="Twitch" /></a>
            </div>
            <p>I am currently a student of FIT ČVUT in Prague. I do mainly programming and I also work on some of our games's stories. Creating games for me was always a passion and a goal, and if I do not want to create something for years, then I need more people. Teamwork can make things not only faster but better and a studio is certainly a way to create such team, a team of people who want to do similar things that I do - craft games.</p>
        </div>
    </div>

)

export default AboutUs;
