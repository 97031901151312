import React from 'react';

import './get-in-touch-banner.styles.css';
import PhoneIcon from '../../img/phone.svg';
import MailIcon from '../../img/email.svg'

class GetInTouchBanner extends React.Component {

    constructor(props) {
        super(props);

        this.state = { isHidden: false };
        console.log(this.state);

        this.showHide = this.showHide.bind(this);
    }
    showHide() {
        this.setState(state => ({
            isHidden: !this.state.isHidden

        }));
        //this.setState({ isHidden: false ? true : false });
        console.log(this.state);
    }

    render() {
        return (
            <div className={this.state.isHidden ? "getInTouchHidden" : "getInTouch"}>

                <span className="hamburgerContact" htmlFor="toggle" onClick={this.showHide}>&#9776;</span>

                {this.state.isHidden ? <img className="hiddenIconMail" src={MailIcon} /> : ""}
                {/*this.state.isHidden ? <img className="hiddenIconPhone" src={PhoneIcon} /> : ""*/}
                <h1>Get In Touch With Us</h1>

				<div className="eachPersonContact">
                    <div className="personInfo">
                        <p><span>Business Email</span></p>
                    </div>
                    <div className="contactInfo">
                        <p> <img src={MailIcon} alt="mail" /> contact@detailsoftware.cz</p>
                    </div>
				</div>
                {/*<div className="eachPersonContact">
                    <div className="personInfo">
                        <p><span>John Doe</span></p>
                        <p>CEO</p>
                    </div>
                    <div className="contactInfo">
                        <p> <img src={MailIcon} alt="" /> john.doe@gmail.com</p>
                        <p><img src={PhoneIcon} alt="" /> +420 777 222 333</p>
                    </div>
                </div>
                <div className="eachPersonContact">
                    <div className="personInfo">
                        <p><span>John Doe</span></p>
                        <p>CEO</p>
                    </div>
                    <div className="contactInfo">
                        <p> <img src={MailIcon} alt="" /> john.doe@gmail.com</p>
                        <p><img src={PhoneIcon} alt="" /> +420 777 222 333</p>
                    </div>
				</div>*/}
            </div>
        );
    }

}



export default GetInTouchBanner;