import React from 'react';

import FacebookLogo from '../../img/facebook_logo.svg';
import InstagramLogo from '../../img/instagram_logo.svg';
import TwitterLogo from '../../img/twitter_logo.svg';
import TwitchLogo from '../../img/twitch_logo.svg';
import YoutubeLogo from '../../img/youtube_logo.svg';
import DiscordLogo from '../../img/discord_logo.svg';

import './social-media-icons.styles.css';

const SocialMediaIcons = () => (
    <div className="socialMediaIcons">
		<a href="https://discord.gg/yBCvvQJ" target="blank"><img src={DiscordLogo} alt="Discord" /></a>
        <a href="https://www.facebook.com/DetailSoft" target="blank"><img src={FacebookLogo} alt="Facebook" /></a>
		{/*<a href="https://www.instagram.com/" target="blank"><img src={InstagramLogo} alt="Instagram" /></a>
        <a href="https://www.twitter.com/" target="blank"><img src={TwitterLogo} alt="Twitter" /></a>
        <a href="https://www.twitch.com/" target="blank"><img src={TwitchLogo} alt="Twitch" /></a>*/}
        <a href="https://www.youtube.com/channel/UCqFZ8pwVLfz_4OaxBPNFH8g" target="blank"><img src={YoutubeLogo} alt="Youtube" /></a>
    </div>
)

export default SocialMediaIcons;