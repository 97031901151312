import React from 'react';
import { Slide } from 'react-slideshow-image';
import ProjectData from '../../dataFiles/projectsData.json';
import client from '../service/client';

import './slider.styles.css';
import sampleImage from '../../img/sample_render.jpg';
import AndroidLogo from '../../img/android_logo.svg';
import AppleLogo from '../../img/apple_logo.svg';
import LinuxLogo from '../../img/linux_logo.svg';
import WindowsLogo from '../../img/windows_logo.svg';
import Mouse from '../../img/mouse.svg';

const properties = {
    duration: 5000, //původně 5000
    transitionDuration: 500,
    infinite: true,
    indicators: false,
    arrows: true,
    pauseOnHover: false,
    onChange: (oldIndex, newIndex) => {
        console.log(`slide transition from ${oldIndex} to ${newIndex}`);
    }
}

class Slideshow extends React.Component {

    constructor() {
        super()
        this.state = { articles: [] }
    }
    componentDidMount() {

        client.getEntries({ content_type: 'homepageSlideshow' }).then((response) => {
            this.setState({ articles: response.items })
        })
    }
    render() {
        return (
            <div className="slide-container">

                <Slide {...properties}>

                    {this.state.articles.map((article, i) => {

                        return (
                            <div >
                                <div className="each-slide">
                                    <div style={{ backgroundImage: `url(${article.fields.backgroundImage.fields.file.url}?w=1920&h=1080)` }}>
                                        <div className="slideText">
                                            <h1 className="slideHeadline">{article.fields.headline}</h1>
                                            {/*<img src={NewBanner} alt="FFFFUUUUUCK" className="newBanner" />*/}
                                            <p className="slideParagraph">{`${article.fields.content.substr(0, 200)}...`}</p>
                                        </div>
                                        <div className="icons">
                                            {article.fields.androidSupport ? <img src={AndroidLogo} alt="Android" className="OS" /> : null}
                                            {article.fields.appleSupport ? <img src={AppleLogo} alt="Apple" className="OS" /> : null}
                                            {article.fields.linuxSupport ? <img src={LinuxLogo} alt="Linux" className="OS" /> : null}
                                            {article.fields.windowsSupport ? <img src={WindowsLogo} alt="Windows" className="OS" /> : null}
                                        </div>
                                        <div className="spacer"></div>

                                    </div>
                                </div>
                            </div>
                        )

                    })}

                </Slide>
                {/* <img src={Mouse} alt="Windows" className="mouse" />*/}
            </div >
        )
    }
}
export default Slideshow;